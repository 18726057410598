import React, { useState } from 'react';
import { navigate } from 'gatsby';

import { Row, Col, Pagination, Select } from 'antd';
import styled from 'styled-components';
import Scroll from 'react-scroll';
import PropTypes from 'prop-types';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NoDataSvg from '../../../../static/svgs/no-data.inline.svg';
import PropertyCardComponent from './PropertyCardComponent';

const { scroller } = Scroll;
const { Element } = Scroll;

const { Option } = Select;

// const StyledFeaturedProperty = styled.div`
//   .featured-image {
//     background-image: ${props => `url(${props.coverImage})`};
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;
//     height: 260px;

//     .action-buttons {
//       position: absolute;
//       top: 24px;
//       right: 8px;
//       display: flex;
//       box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);

//       .link-button,
//       .contact-button {
//         padding: 8px 12px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }

//       .link-button {
//         background-color: ${props => props.theme.primeBluePalette.original};
//         color: ${props => props.theme.whitePalette.original};
//         border-radius: 4px 0px 0px 4px;
//       }

//       .contact-button {
//         background-color: ${props => props.theme.whitePalette.original};
//         font-size: 18px;
//       }
//     }

//     .styled-row {
//       height: 100%;

//       .buttons-wrapper {
//         display: flex;
//         margin: 12px 8px;

//         .styled-button {
//           background-color: ${props => props.theme.whitePalette.original};
//           color: ${props => props.theme.primeBluePalette.dark};
//           padding: 5px 6px;
//           font-size: 14px;
//           line-height: 14px;
//           margin-left: 12px;
//           cursor: pointer;
//         }
//       }
//     }
//   }

//   .styled-details {
//     background-color: ${props => props.theme.primeBluePalette.original};
//     padding: 16px 20px;

//     h3 {
//       color: ${props => props.theme.whitePalette.original};
//       margin-bottom: 4px;
//     }

//     p {
//       color: ${props => props.theme.primeBluePalette.dark};
//     }

//     .specs {
//       display: flex;

//       .details {
//         display: flex;
//         align-items: center;

//         .styled-icon {
//           margin-right: 8px;
//           font-size: 18px;
//           color: ${props => props.theme.primeYellowPalette.original};
//         }

//         h6 {
//           color: ${props => props.theme.whitePalette.original};
//           margin: 0;
//         }
//       }

//       .details:not(:first-child) {
//         margin-left: 24px;
//       }
//     }

//     .styled-divider {
//       border-color: ${props => props.theme.primeBluePalette.dark};
//       margin: 16px 0px;
//       width: 80%;
//       min-width: 80%;
//     }
//   }
// `;

const StyledResultsWrapper = styled.div`
  width: 100%;
  .sort-control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    > div {
      margin-right: 8px;
    }
    > div:last-child {
      margin-right: 0px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      justify-content: flex-start;
    }

    @media only screen and (max-width: 375px) {
      margin-top: 6px;
      justify-content: flex-start;
      > div {
        margin-right: 8px;
      }
      > div:last-child {
        margin-right: 10px;
        margin-left: auto;
        margin-top: 6px;
      }
    }
  }

  .results-label {
    font-size: 1.1em;
    span {
      font-weight: bold;
      color: ${props => props.theme.primeBluePalette.original};
    }
  }
`;

function ListViewPropertiesComponent({
  // featuredProperty,
  properties,
  propType,
  page,
  searchParams,
  onPageChange,
}) {
  const defaultPageSize = 12;
  const [pageSize, setPageSize] = useState(12);
  const [sortBy, setSortBy] = useState('priceLtH');

  const pagedProperties = properties.sort((propA, propB) => {
    let tempASize = propA.range.maxFloorArea;
    let tempBSize = propB.range.maxFloorArea;
    switch (sortBy) {
      case 'priceLtH':
        return propA.range.maxPrice - propB.range.maxPrice;
      case 'priceHtL':
        return propB.range.maxPrice - propA.range.maxPrice;
      case 'sizeLtH':
        if (propA.property_type === 'CL' || propA.property_type === 'IL')
          tempASize = propA.range.maxLotArea;

        if (propB.property_type === 'CL' || propB.property_type === 'IL')
          tempBSize = propB.range.maxLotArea;

        return tempASize - tempBSize;
      case 'sizeHtL':
        if (propA.property_type === 'CL' || propA.property_type === 'IL')
          tempASize = propA.range.maxLotArea;

        if (propB.property_type === 'CL' || propB.property_type === 'IL')
          tempBSize = propB.range.maxLotArea;
        return tempBSize - tempASize;

      default:
        return 1;
    }
  });

  // const formatter = new Intl.NumberFormat('en-US', {
  //   minimumFractionDigits: 2,
  // });

  function pageNavigate(pg) {
    let baseSearchPath = `/find-a-property/search?location=${searchParams.location}&property-type=${searchParams.propertyType}&page=${pg}`;
    baseSearchPath = `${baseSearchPath}&price-range=${searchParams.priceRange.toString()}`;
    baseSearchPath = `${baseSearchPath}&size-range=${searchParams.sizeRange.toString()}`;

    switch (searchParams.propertyType) {
      case 'Office':
        baseSearchPath = `${baseSearchPath}&accreditation=${searchParams.office.accreditations.toString()}`;
        baseSearchPath = `${baseSearchPath}&telco=${searchParams.office.telcoProviders.toString()}`;
        baseSearchPath = `${baseSearchPath}&turnover=${searchParams.office.turnoverConditions.toString()}`;
        break;
      case 'Retail':
        baseSearchPath = `${baseSearchPath}&development-type=${searchParams.retail.developmentType.toString()}`;
        baseSearchPath = `${baseSearchPath}&turnover=${searchParams.retail.turnoverConditions.toString()}`;
        baseSearchPath = `${baseSearchPath}&occupancy=${searchParams.retail.readyForOccupancy.toString()}`;
        baseSearchPath = `${baseSearchPath}&parking-slots=${searchParams.retail.parkingSlots.toString()}`;
        break;
      case 'Industrial':
        baseSearchPath = `${baseSearchPath}&electric-load=${searchParams.industrial.electricLoad.toString()}`;
        baseSearchPath = `${baseSearchPath}&tenant-restrictions=${searchParams.industrial.tenantRestrictions.toString()}`;
        break;
      default:
        baseSearchPath = `${baseSearchPath}`;
        break;
    }
    return baseSearchPath;
  }

  return (
    <StyledResultsWrapper>
      {/* <div>
        <Row type="flex" gutter={[16, 16]}>
          <Col span={24}>
            <StyledFeaturedProperty coverImage={featuredProperty.image}>
              <div className="featured-image">
                <div className="action-buttons">
                  <div className="link-button">
                    <FontAwesomeIcon icon="external-link-alt" />
                  </div>
                  <div className="contact-button">
                    <FontAwesomeIcon icon="envelope" />
                  </div>
                </div>
                <Row type="flex" align="bottom" className="styled-row">
                  <Col>
                    <div className="buttons-wrapper">
                      <div className="styled-button">
                        <FontAwesomeIcon icon="expand" />
                      </div>
                      <div className="styled-button">
                        <FontAwesomeIcon icon="map-marked-alt" /> Location Map
                      </div>
                      <div className="styled-button">
                        <FontAwesomeIcon icon="images" /> Gallery
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="styled-details">
                <h3>{featuredProperty.propertyName}</h3>
                <p>{featuredProperty.location}</p>
                <Divider className="styled-divider" />
                <div className="specs">
                  <div className="details">
                    <FontAwesomeIcon icon="building" className="styled-icon" />
                    <h6>{featuredProperty.classification}</h6>
                  </div>
                  <div className="details">
                    <FontAwesomeIcon icon="file-alt" className="styled-icon" />
                    <h6>{featuredProperty.lease_sale}</h6>
                  </div>
                  <div className="details">
                    <FontAwesomeIcon
                      icon="expand-arrows-alt"
                      className="styled-icon"
                    />
                    <h6>{featuredProperty.gfa}</h6>
                  </div>
                </div>
              </div>
            </StyledFeaturedProperty>
          </Col>
        </Row>
      </div> */}

      <Element name="search-result">
        {properties.length > 0 ? (
          <Row type="flex" align="middle" style={{ marginBottom: '10px' }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="results-label">
                Showing{' '}
                <span>
                  {properties.length} {propType.toLowerCase()} properties
                </span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="sort-control">
                <div>Sort By</div>
                <Select
                  defaultValue="Price - Lowest to Highest"
                  onChange={sort => {
                    onPageChange(1);
                    navigate(pageNavigate(1));
                    setSortBy(sort);
                  }}
                >
                  <Option value="priceLtH">Price - Lowest to Highest</Option>
                  <Option value="priceHtL">Price - Highest to Lowest</Option>
                  <Option value="sizeLtH">Size - Smallest to Biggest</Option>
                  <Option value="sizeHtL">Size - Biggest to Smallest</Option>
                </Select>

                <Select
                  defaultValue="12"
                  style={{ width: 75 }}
                  onChange={pgSize => {
                    onPageChange(1);
                    navigate(pageNavigate(1));
                    setPageSize(pgSize);
                  }}
                >
                  {[12, 24, 48, 100].map(pgSize => (
                    <Option key={pgSize} value={pgSize}>
                      {pgSize}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
        ) : (
          <Row type="flex" justify="center" align="middle">
            <Col span={24} style={{ textAlign: 'center' }}>
              <NoDataSvg style={{ width: '60%', height: 450, marginTop: 20 }} />
              <h3>No properties found</h3>
            </Col>
          </Row>
        )}

        <Row type="flex" gutter={[16, 16]}>
          {pagedProperties
            .slice(
              (page - 1) * pageSize,
              properties.length < pageSize
                ? properties.length
                : pageSize + (page - 1) * pageSize
            )
            .map(value => (
              <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} key={value.id}>
                {/* List View of properties using dummy data */}
                <PropertyCardComponent value={value} />
              </Col>
            ))}
        </Row>
      </Element>

      {properties.length > 0 ? (
        <div style={{ textAlign: 'right', marginTop: 20 }}>
          <Pagination
            defaultCurrent={1}
            current={page}
            total={properties.length}
            defaultPageSize={defaultPageSize}
            pageSize={pageSize}
            showSizeChanger={false}
            responsive
            onChange={(pg, pgSize) => {
              setPageSize(pgSize);
              scroller.scrollTo('search-result', {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -109,
              });

              onPageChange(pg);
              navigate(pageNavigate(pg));
            }}
          />
        </div>
      ) : null}
    </StyledResultsWrapper>
  );
}

ListViewPropertiesComponent.propTypes = {
  // featuredProperty: PropTypes.object,
  searchParams: PropTypes.object,
  properties: PropTypes.array,
  propType: PropTypes.string,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
};

ListViewPropertiesComponent.defaultProps = {
  page: 1,
  // featuredProperty: {
  //   image:
  //     'https://assets.primephilippines.com/images/project-listings/thumbnail/thumb_tsq.jpg',
  //   propertyName: 'Triump Square',
  //   location: 'along Quezon Avenue, Quezon City',
  //   classification: 'Office Building',
  //   lease_sale: 'For Lease',
  //   gfa: 'N/A',
  // },
};

export default ListViewPropertiesComponent;
