/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { navigate } from '@reach/router';

import styled from 'styled-components';
import { Button, Divider, Avatar } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from 'react-slick';
import LazyImage from 'react-lazy-progressive-image';
import AppointmentModalComponent from '../AppointmentModalComponent';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledPropertyCard = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
  height: 100%;

  position: relative;
  display: flex;
  flex-direction: column;

  .avatar-wrapper {
    padding: 12px 0px 12px 0px;
  }

  .featured-image {
    background-image: ${props => `url(${props.coverImage})`},
      url('https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 250px;
    border-bottom: 1px solid #eee;
  }

  .details {
    flex: 1;
    > div:first-child {
      padding: 20px 20px 0 20px;
      .property-name,
      .address {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .property-name {
        color: ${props => props.theme.primeBluePalette.original};
        margin-bottom: 0px;
        line-height: 1.5;
      }

      .address {
        font-size: 0.8em;
        opacity: 0.9;
        margin-bottom: 8px;
      }
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .size-range {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0px;
    }
    svg {
      opacity: 0.8;
      margin-right: 8px;
    }

    .value {
      font-size: 1.1em;
      line-height: 1;
      font-weight: bold;
    }
    .label {
      font-size: 80%;
      line-height: 1;
      opacity: 0.8;
    }
  }

  .ant-divider.ant-divider-horizontal {
    margin: 12px 0px;
  }
  .schedule-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    > div:first-child {
      padding: 0px 5px;
    }
    > div:last-child {
      font-weight: bold;
      padding: 6px 12px;
      color: ${props => props.theme.bluePalette.original};
      border: 1px solid ${props => props.theme.bluePalette.original};
      border-radius: 20px;
      cursor: pointer;
      :hover {
        color: ${props => props.theme.primeYellowPalette.original};
        background-color: ${props => props.theme.primeBluePalette.original};
      }
    }
  }

  .top-action {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 0.8em;
    line-height: 1;
  }

  .building-grade {
    border-radius: 4px;
    padding: 6px 8px;
    background-color: rgba(0, 0, 0, 0.5);
    color: ${props => props.theme.primeYellowPalette.original};
  }

  .slick-slide {
    background-color: ${props => props.theme.primeBluePalette.original};

    img {
      margin-bottom: 0px;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 250px;
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center;
    }
  }

  .slick-dots {
    position: absolute;
    text-align: center;
    bottom: 15px;
  }

  .slick-dots li button:before {
    font-size: 12px;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li.slick-active button:before {
    color: ${props => props.theme.bluePalette.light};
  }

  .slick-prev:before,
  .slick-next:before {
    color: ${props => props.theme.bluePalette.light};
  }
  .slick-prev {
    z-index: 1;
    left: 15px;
  }

  .slick-next {
    right: 15px;
  }

  .ant-avatar-group.ant-avatar {
    background-color: ${props => props.theme.bluePalette.light};
  }
`;

const settings = {
  dots: true,
  lazyLoad: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function PropertyCardComponent({ value }) {
  const data = useStaticQuery(graphql`
    {
      allPropertiesAllUsers {
        nodes {
          id
          status
          profile_pic
          position
          name
          focus
          email
          department
          area
        }
      }
    }
  `);

  const [isImgErr, setIsImgErr] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // area, focus

  function getUsersAvatar(propertyType) {
    let focusType = 'Office';
    switch (propertyType) {
      case 'Office':
        focusType = 'Office';
        break;
      case 'Retail':
      case 'IW':
      case 'IL':
      case 'CL':
        focusType = 'Retail';
        break;

      default:
        focusType = 'Office';
        break;
    }

    return data.allPropertiesAllUsers.nodes
      .map(user => ({
        ...user,
        profile_pic:
          user.profile_pic !== 'uploads/prof/dummy_prof.png'
            ? `https://assets.primecore.dev/users/profile_images/${user.profile_pic}`
            : ``,
      }))
      .sort(() => Math.random() - 0.5)
      .filter(user => user.focus === focusType)
      .slice(0, 3);
  }

  return (
    <StyledPropertyCard
      coverImage={
        value.images !== null
          ? value.images.data.length - 1 > 0
            ? `${process.env.IMAGES_URL_PATH}/${value.images.data[0].thumb_path}`
            : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg'
          : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg'
      }
    >
      <AppointmentModalComponent
        visibility={modalOpen}
        onVisibilityChanged={() => {
          setModalOpen(false);
        }}
        propertyName={value.property_name}
        address={value.address}
        propertyType={value.property_type}
      />
      {typeof value.building_grade !== 'undefined' ? (
        <div className="top-action">
          {value.building_grade.toLowerCase() === 'premium' ||
          value.building_grade.toLowerCase() === 'class a' ||
          value.building_grade.toLowerCase() === 'grade a' ? (
            <div className="building-grade">
              <FontAwesomeIcon icon="star" />{' '}
              {value.building_grade.toLowerCase() === 'grade a'
                ? 'Class A'
                : value.building_grade}
            </div>
          ) : null}
        </div>
      ) : null}

      <div className="featured-image">
        <Slider {...settings}>
          {value.images.data.slice(0, 5).map(listing => (
            <div className="slick-slide" key={listing.image_path}>
              <LazyImage
                placeholder="https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg"
                src={`${process.env.IMAGES_URL_PATH}/${listing.image_path}`}
              >
                {src => <img src={src} alt={value.property_name} />}
              </LazyImage>
            </div>
          ))}
        </Slider>
      </div>
      <div className="details">
        <div className="">
          <h4 className="property-name">{value.property_name}</h4>
          <p className="address">{value.address}</p>
          <div className="size-range">
            <div>
              <FontAwesomeIcon fixedWidth size="1x" icon="expand" />
            </div>
            {value.range.maxFloorArea > 0 || value.range.minFloorArea > 0 ? (
              <div>
                <p className="value">
                  {value.range.minFloorArea >= value.range.maxFloorArea
                    ? `${new Intl.NumberFormat().format(
                        value.range.minFloorArea
                      )} sqm`
                    : value.range.minFloorArea === 0
                    ? `${new Intl.NumberFormat().format(
                        value.range.maxFloorArea
                      )} sqm`
                    : `${new Intl.NumberFormat().format(
                        value.range.minFloorArea
                      )} - ${new Intl.NumberFormat().format(
                        value.range.maxFloorArea
                      )} sqm`}
                </p>
                <p className="label">Floor Area</p>
              </div>
            ) : null}
            {value.range.maxLotArea > 0 || value.range.minLotArea > 0 ? (
              <div>
                <p className="value">
                  {value.range.minLotArea >= value.range.maxLotArea
                    ? `${new Intl.NumberFormat().format(
                        value.range.minLotArea
                      )} sqm`
                    : value.range.minLotArea === 0
                    ? `${new Intl.NumberFormat().format(
                        value.range.maxLotArea
                      )} sqm`
                    : `${new Intl.NumberFormat().format(
                        value.range.minLotArea
                      )} - ${new Intl.NumberFormat().format(
                        value.range.maxLotArea
                      )} sqm`}
                </p>
                <p className="label">Lot Area</p>
              </div>
            ) : null}
            {value.range.maxLotArea === 0 &&
            value.range.minLotArea === 0 &&
            value.range.maxFloorArea === 0 &&
            value.range.minFloorArea === 0 ? (
              <div>
                <p className="value">To follow</p>
                <p className="label">Area</p>
              </div>
            ) : null}
          </div>
          <Divider />
          <div className="schedule-button">
            <Avatar.Group>
              {getUsersAvatar(value.property_type).map((user, i) => (
                <Avatar
                  key={`${i}${user.name}`}
                  src={`${user.profile_pic}`}
                  alt={`${user.name}`}
                  onError={() => setIsImgErr(true)}
                  style={{
                    backgroundColor: '#003366',
                    color: '#fad127',
                  }}
                >
                  {isImgErr ? `${user.name}`.toUpperCase().charAt(0) : null}
                </Avatar>
              ))}
            </Avatar.Group>
            <div
              onClick={() => {
                setModalOpen(true);
              }}
              aria-label="Appointment Button"
              onKeyDown={null}
              role="button"
              tabIndex="0"
            >
              Book an appointment?
            </div>
          </div>
        </div>
        <div>
          <Button
            type="primary"
            block
            size="large"
            onClick={() => {
              navigate(`\\listings\\${value.slug}`);
            }}
          >
            VIEW DETAILS
          </Button>
        </div>
      </div>
    </StyledPropertyCard>
  );
}

PropertyCardComponent.propTypes = {
  // featuredProperty: PropTypes.object,
  value: PropTypes.object,
};
export default PropertyCardComponent;
