import React from 'react';
import PropTypes from 'prop-types';

import { Select, Checkbox, Switch } from 'antd';
const { Option } = Select;

function RetailAdditionalFiltersComponent({
  retail,
  getDevelopmentType,
  getReadyForOccupancy,
  getParkingSlots,
  getTurnover,
}) {
  const turnoverConditions = [
    'Bare shell',
    'Warm shell',
    'Semi-fitted',
    'Fitted',
    'As is where is',
  ];
  const typeOfDevelopment = [
    'All',
    'Commercial Lots',
    'Community Mall',
    'Commercial Strip',
    'Commercial Building',
    'Regional Mall',
  ];
  return (
    <div>
      <div className="filter-field">
        <p>
          <b>Type of Development</b>
        </p>
        <div>
          <Select
            defaultValue={retail.developmentType}
            style={{ width: '100%' }}
            onChange={e => {
              getDevelopmentType(e);
            }}
          >
            {typeOfDevelopment.map(development => (
              <Option key={development} value={development}>
                {development}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      <div className="filter-field">
        <p>
          <b>Turnover Conditions</b>
        </p>
        <div>
          <Checkbox.Group
            defaultValue={retail.turnoverConditions}
            options={turnoverConditions}
            onChange={e => {
              getTurnover(e);
            }}
          />
        </div>
      </div>

      <div className="filter-field">
        <p>
          <b>
            Ready for occupancy?{' '}
            <Switch
              defaultChecked={retail.readyForOccupancy}
              checkedChildren="YES"
              unCheckedChildren="NO"
              onChange={e => {
                getReadyForOccupancy(e);
              }}
            />
          </b>
        </p>
      </div>

      <div className="filter-field">
        <p>
          <b>
            Parking slots availability?{' '}
            <Switch
              defaultChecked={retail.parkingSlots}
              checkedChildren="YES"
              unCheckedChildren="NO"
              onChange={e => {
                getParkingSlots(e);
              }}
            />
          </b>
        </p>
      </div>
    </div>
  );
}
RetailAdditionalFiltersComponent.propTypes = {
  retail: PropTypes.object,
  getDevelopmentType: PropTypes.func,
  getReadyForOccupancy: PropTypes.func,
  getParkingSlots: PropTypes.func,
  getTurnover: PropTypes.func,
};

export default RetailAdditionalFiltersComponent;
