import React from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

function OfficeAdditionalFiltersComponent({
  getTurnover,
  getAccreditations,
  getTelcoProviders,
  office,
}) {
  const turnoverConditions = [
    'Bare shell',
    'Warm shell',
    'Semi-fitted',
    'Fitted',
    'As is where is',
  ];
  const accreditations = ['PEZA', 'LEED', 'BERDE - PhilGBC', 'CEZA'];
  const telcoProviders = [
    'PT&T',
    'PLDT',
    'Globe',
    'Converge',
    'Now Corp',
    'Bayantel',
    'Digitel',
    'Eastern Telecom',
    'Others',
  ];

  return (
    <div>
      <div className="filter-field">
        <p>
          <b>Accreditations</b>
        </p>
        <div>
          <Checkbox.Group
            defaultValue={office.accreditations}
            options={accreditations}
            onChange={e => {
              getAccreditations(e);
            }}
          />
        </div>
      </div>

      <div className="filter-field">
        <p>
          <b>Telco Providers</b>
        </p>
        <div>
          <Checkbox.Group
            defaultValue={office.telcoProviders}
            options={telcoProviders}
            onChange={e => {
              getTelcoProviders(e);
            }}
          />
        </div>
      </div>

      <div className="filter-field">
        <p>
          <b>Turnover Conditions</b>
        </p>
        <div>
          <Checkbox.Group
            defaultValue={office.turnoverConditions}
            options={turnoverConditions}
            onChange={e => {
              getTurnover(e);
            }}
          />
        </div>
      </div>
    </div>
  );
}

OfficeAdditionalFiltersComponent.propTypes = {
  getAccreditations: PropTypes.func,
  getTelcoProviders: PropTypes.func,
  getTurnover: PropTypes.func,
  office: PropTypes.object,
};

export default OfficeAdditionalFiltersComponent;
