/* eslint-disable radix */
import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import * as queryString from 'query-string';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import AdvancedFiltersComponent from '../../components/find-your-property/AdvancedFiltersComponent';
import { filterAllListings } from '../../utilities/filter-listings';

import ListViewPropertiesComponent from '../../components/find-your-property/list-view/ListViewPropertiesComponent';

const StyledDivWrapper = styled.div`
  padding-top: 89px;
  max-width: 1440px;
  margin: auto;
  background-color: #f0f0f0aa;

  overflow: hidden;
`;

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_LISTINGS_DATA':
      return action.item;
    case 'UPDATE_PAGE':
      return action.item;
    default:
      return state;
  }
}

function SearchPage({ location }) {
  const data = useStaticQuery(graphql`
    {
      allPropertiesCommercialLots {
        nodes {
          id
          electrical_capacity
          electric_meter
          property_name
          authority_type
          price_per_sqm
          selling_price
          lot_area
          floor_area
          area_scope
          availability
          city
          classification
          fibre_optic_capable
          floor_area_float_val
          handover_condition
          generator_backup_power
          latitude
          level
          listing_code
          listing_id
          listing_image {
            data {
              image_path
              thumb_path
            }
          }
          location
          longitude
          lot_area_float_val
          peza_accredited
          parking_rate
          price_per_sqm_float_val
          property_type
          telecommunications_provider
          tenant_preferences
          tenant_restrictions
          total_parking_slots
        }
      }
      allPropertiesIndustrialLots {
        nodes {
          id
          electrical_capacity
          electric_meter
          property_name
          authority_type
          price_per_sqm
          selling_price
          lot_area
          floor_area
          area_scope
          availability
          city
          classification
          fibre_optic_capable
          floor_area_float_val
          handover_condition
          generator_backup_power
          latitude
          level
          listing_code
          listing_id
          listing_image {
            data {
              image_path
              thumb_path
            }
          }
          location
          longitude
          lot_area_float_val
          peza_accredited
          parking_rate
          price_per_sqm_float_val
          property_type
          telecommunications_provider
          tenant_preferences
          tenant_restrictions
          total_parking_slots
        }
      }

      allPropertiesWarehouses {
        nodes {
          id
          electrical_capacity
          electric_meter
          property_name
          authority_type
          price_per_sqm
          selling_price
          lot_area
          floor_area
          area_scope
          availability
          city
          classification
          fibre_optic_capable
          floor_area_float_val
          handover_condition
          generator_backup_power
          latitude
          level
          listing_code
          listing_id
          listing_image {
            data {
              image_path
              thumb_path
            }
          }
          location
          longitude
          lot_area_float_val
          peza_accredited
          parking_rate
          price_per_sqm_float_val
          property_type
          telecommunications_provider
          tenant_preferences
          tenant_restrictions
          total_parking_slots
        }
      }

      allPropertiesRetailProjects {
        nodes {
          id
          electric_capacity
          electric_meter
          project_name
          address
          area_scope
          authority_type
          availability
          available_units
          building_grade
          city
          generator_backup_power
          gross_floor_area
          handover_condition
          longitude
          lot_area
          latitude
          net_leasable
          no_of_storey
          no_passenger_elevator
          operating_hours
          property_type
          tenant_restrictions
          tenant_preferences
          total_available_area
          total_parking_lot
          range {
            maxFloorArea
            minFloorArea
            minPrice
            maxPrice
          }
          project_code
          project_id
          owner_developer
          project_image {
            data {
              thumb_path
              image_path
            }
          }
          office {
            data {
              peza_accredited
              telecommunications_provider
              fibre_optic_capable
            }
          }
        }
      }

      allPropertiesOfficeProjects {
        nodes {
          id
          electric_capacity
          electric_meter
          project_name
          address
          area_scope
          authority_type
          availability
          available_units
          building_grade
          city
          generator_backup_power
          gross_floor_area
          handover_condition
          longitude
          lot_area
          latitude
          net_leasable
          no_of_storey
          no_passenger_elevator
          operating_hours
          property_type
          tenant_restrictions
          tenant_preferences
          total_available_area
          total_parking_lot
          range {
            maxFloorArea
            minFloorArea
            minPrice
            maxPrice
          }
          project_code
          project_id
          owner_developer
          project_image {
            data {
              thumb_path
              image_path
            }
          }
          office {
            data {
              peza_accredited
              telecommunications_provider
              fibre_optic_capable
            }
          }
        }
      }
    }
  `);

  // extract saerch filters in url params
  const [pageState, pageDispatch] = useReducer(
    reducer,
    queryString.parse(location.search).page || 1
  );

  const propType = queryString.parse(location.search)['property-type'];
  const propLocation = queryString.parse(location.search).location;
  const sizeRange = `${queryString.parse(location.search)['size-range'] ||
    '0,101153'}`
    .split(',')
    .filter(str => str !== '')
    .map(str => parseFloat(str));
  const priceRange = `${queryString.parse(location.search)['price-range'] ||
    '0,25000'}`
    .split(',')
    .filter(str => str !== '')
    .map(str => parseFloat(str));

  // property-type = Office
  const office = {
    accreditations: `${queryString.parse(location.search).accreditation || ''}`
      .split(',')
      .filter(str => str !== ''),
    telcoProviders: `${queryString.parse(location.search).telco || ''}`
      .split(',')
      .filter(str => str !== ''),
    turnoverConditions: `${queryString.parse(location.search).turnover || ''}`
      .split(',')
      .filter(str => str !== ''),
  };
  // property-type = Retail
  const retail = {
    developmentType: `${queryString.parse(location.search)[
      'development-type'
    ] || 'All'}`,
    turnoverConditions: `${queryString.parse(location.search).turnover || ''}`
      .split(',')
      .filter(str => str !== ''),
    readyForOccupancy:
      `${queryString.parse(location.search).occupancy || 'false'}` === 'true',
    parkingSlots:
      `${queryString.parse(location.search)['parking-slots'] || 'false'}` ===
      'true',
  };

  // property-type = Industrial
  const industrial = {
    electricLoad: `${queryString.parse(location.search)['electric-load'] ||
      'All'}`,
    vehicleCapacity: `${queryString.parse(location.search)[
      'vehicle-capacity'
    ] || 'All'}`,
    loadingDock:
      `${queryString.parse(location.search)['loading-dock'] || 'false'}` ===
      'true',
    tenantRestrictions: `${queryString.parse(location.search)[
      'tenant-restrictions'
    ] || ''}`
      .split(',')
      .filter(str => str !== ''),
  };

  const [allListingsState, listingsDispatch] = useReducer(
    reducer,
    filterAllListings(data, {
      location: propLocation || '',
      propertyType: propType || 'Office',
      priceRange,
      sizeRange,
      industrial,
      retail,
      office,
    })
  );
  console.log(allListingsState);

  return (
    <Layout source="Find Your Property">
      <SEO
        title="Find Your Property"
        description="The Leading Commercial Real Estate Consultancy Firm in the Philippines."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'office space for lease, retail space for lease, industrial space for lease, ',
          },
        ]}
      />
      <StyledDivWrapper>
        <Row type="flex" justify="center">
          <Col
            xs={24}
            sm={24}
            md={7}
            lg={8}
            xl={6}
            xxl={6}
            style={{ padding: 16 }}
          >
            <AdvancedFiltersComponent
              propertyType={propType}
              propertyLocation={propLocation}
              page={parseInt(pageState)}
              sizeRange={sizeRange}
              priceRange={priceRange}
              office={office}
              retail={retail}
              industrial={industrial}
              getFiltersData={sParams => {
                listingsDispatch({
                  type: 'UPDATE_LISTINGS_DATA',
                  item: filterAllListings(data, sParams),
                });
                pageDispatch({
                  type: 'UPDATE_PAGE',
                  item: 1,
                });
              }}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={17}
            lg={16}
            xl={18}
            xxl={18}
            style={{ padding: 16 }}
          >
            <div>
              <Row>
                <ListViewPropertiesComponent
                  properties={allListingsState}
                  propType={propType || 'Office'}
                  page={parseInt(pageState)}
                  searchParams={{
                    location: propLocation || '',
                    propertyType: propType || 'Office',
                    priceRange,
                    sizeRange,
                    industrial,
                    retail,
                    office,
                  }}
                  onPageChange={pg => {
                    pageDispatch({
                      type: 'UPDATE_PAGE',
                      item: pg,
                    });
                  }}
                />
              </Row>
            </div>
          </Col>
        </Row>
      </StyledDivWrapper>
    </Layout>
  );
}

SearchPage.propTypes = {
  location: PropTypes.any,
};

export default SearchPage;
