import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { Row, Col, Button, Divider, Input, Select, Slider } from 'antd';
import OfficeAdditionalFiltersComponent from './OfficeAdditionalFiltersComponent';
import RetailAdditionalFiltersComponent from './RetailAdditionalFiltersComponent';
import IndustrialAdditionalFiltersComponent from './IndustrialAdditionalFiltersComponent';

const { Option } = Select;

const StyledDivWrapper = styled.div`
  padding: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  background-color: white;

  .filter-field {
    margin-bottom: 16px;

    p {
      margin-bottom: 6px;
    }
  }

  .filter-field.filter-footer {
    margin-bottom: 0px;
  }

  .ant-divider-horizontal {
    margin: 10px 0;
  }
`;

function AdvancedFiltersComponent(props) {
  const {
    propertyType,
    propertyLocation,
    priceRange,
    sizeRange,
    office,
    industrial,
    retail,
    getFiltersData,
  } = props;

  const priceLimit = [0, 25000]; // checked manually from primecore.dev
  const sizeLimit = [0, 101153]; // checked manually from primecore.dev

  const [searchParams, setSearchParams] = useState({
    location: propertyLocation || '',
    propertyType: propertyType || 'Office',
    priceRange,
    sizeRange,
    office,
    industrial,
    retail,
  });

  return (
    <StyledDivWrapper>
      <div>
        <div className="filter-field">
          <p>
            <b>Location</b>
          </p>
          <div>
            <Input
              placeholder="Search by location, city or street"
              defaultValue={searchParams.location}
              onChange={e => {
                setSearchParams({
                  ...searchParams,
                  location: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className="filter-field">
          <p>
            <b>Property Type</b>
          </p>
          <Select
            defaultValue={searchParams.propertyType}
            style={{ width: '100%' }}
            onChange={e => {
              setSearchParams({
                ...searchParams,
                propertyType: e,
                industrial: {
                  electricLoad: 'All',
                  vehicleCapacity: 'All',
                  loadingDock: false,
                  tenantRestrictions: [],
                },
                retail: {
                  developmentType: 'All',
                  turnoverConditions: [],
                  readyForOccupancy: false,
                  parkingSlots: false,
                },
                office: {
                  accreditations: [],
                  telcoProviders: [],
                  turnoverConditions: [],
                },
              });
            }}
          >
            <Option value="Office">Office</Option>
            <Option value="Retail">Retail</Option>
            <Option value="Industrial">Industrial</Option>
          </Select>
        </div>
        <div className="filter-field">
          <p>
            <b>Size Range</b>
          </p>
          <Slider
            range
            reverse={false}
            defaultValue={searchParams.sizeRange}
            value={searchParams.sizeRange}
            min={sizeLimit[0]}
            max={sizeLimit[1]}
            onChange={value => {
              setSearchParams({
                ...searchParams,
                sizeRange: value,
              });
            }}
          />
          <Row type="flex" justify="space-between">
            <Col span={10}>
              <Input
                placeholder="min"
                size="small"
                value={searchParams.sizeRange[0]}
                onChange={e => {
                  setSearchParams({
                    ...searchParams,
                    sizeRange: [e.target.value, searchParams.sizeRange[1]],
                  });
                }}
              />
            </Col>
            <Col span={10}>
              <Input
                placeholder="max"
                size="small"
                value={searchParams.sizeRange[1]}
                onChange={e => {
                  setSearchParams({
                    ...searchParams,
                    sizeRange: [searchParams.sizeRange[0], e.target.value],
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="filter-field">
          <p>
            <b>Price Range</b>
          </p>
          <Slider
            range
            reverse={false}
            defaultValue={searchParams.priceRange}
            value={searchParams.priceRange}
            min={priceLimit[0]}
            max={priceLimit[1]}
            onChange={value => {
              setSearchParams({
                ...searchParams,
                priceRange: value,
              });
            }}
          />
          <Row type="flex" justify="space-between">
            <Col span={10}>
              <Input
                placeholder="min"
                size="small"
                value={searchParams.priceRange[0]}
                onChange={e => {
                  setSearchParams({
                    ...searchParams,
                    priceRange: [e.target.value, searchParams.priceRange[1]],
                  });
                }}
              />
            </Col>
            <Col span={10}>
              <Input
                placeholder="max"
                size="small"
                value={searchParams.priceRange[1]}
                onChange={e => {
                  setSearchParams({
                    ...searchParams,
                    priceRange: [searchParams.priceRange[0], e.target.value],
                  });
                }}
              />
            </Col>
          </Row>
        </div>

        <Divider />

        {searchParams.propertyType === 'Office' ? (
          <OfficeAdditionalFiltersComponent
            office={office}
            getTurnover={value => {
              setSearchParams({
                ...searchParams,
                office: {
                  ...searchParams.office,
                  turnoverConditions: value,
                },
              });
            }}
            getAccreditations={value => {
              setSearchParams({
                ...searchParams,
                office: {
                  ...searchParams.office,
                  accreditations: value,
                },
              });
            }}
            getTelcoProviders={value => {
              setSearchParams({
                ...searchParams,
                office: {
                  ...searchParams.office,
                  telcoProviders: value,
                },
              });
            }}
          />
        ) : null}

        {searchParams.propertyType === 'Retail' ? (
          <RetailAdditionalFiltersComponent
            retail={retail}
            getDevelopmentType={value => {
              setSearchParams({
                ...searchParams,
                retail: {
                  ...searchParams.retail,
                  developmentType: value,
                },
              });
            }}
            getReadyForOccupancy={value => {
              setSearchParams({
                ...searchParams,
                retail: {
                  ...searchParams.retail,
                  readyForOccupancy: value,
                },
              });
            }}
            getParkingSlots={value => {
              setSearchParams({
                ...searchParams,
                retail: {
                  ...searchParams.retail,
                  parkingSlots: value,
                },
              });
            }}
            getTurnover={value => {
              setSearchParams({
                ...searchParams,
                retail: {
                  ...searchParams.retail,
                  turnoverConditions: value,
                },
              });
            }}
          />
        ) : null}

        {searchParams.propertyType === 'Industrial' ? (
          <IndustrialAdditionalFiltersComponent
            industrial={industrial}
            getElectricLoad={value => {
              setSearchParams({
                ...searchParams,
                industrial: {
                  ...searchParams.industrial,
                  electricLoad: value,
                },
              });
            }}
            getVehicleCapavity={value => {
              setSearchParams({
                ...searchParams,
                industrial: {
                  ...searchParams.industrial,
                  vehicleCapacity: value,
                },
              });
            }}
            getLoadingDock={value => {
              setSearchParams({
                ...searchParams,
                industrial: {
                  ...searchParams.industrial,
                  loadingDock: value,
                },
              });
            }}
            getTenantRestrictions={value => {
              setSearchParams({
                ...searchParams,
                industrial: {
                  ...searchParams.industrial,
                  tenantRestrictions: value,
                },
              });
            }}
          />
        ) : null}

        <div className="filter-field filter-footer">
          <div>
            <Button
              type="primary"
              block
              onClick={() => {
                let baseSearchPath = `/find-a-property/search?location=${searchParams.location}&property-type=${searchParams.propertyType}&page=1`;
                baseSearchPath = `${baseSearchPath}&price-range=${searchParams.priceRange.toString()}`;
                baseSearchPath = `${baseSearchPath}&size-range=${searchParams.sizeRange.toString()}`;

                switch (searchParams.propertyType) {
                  case 'Office':
                    baseSearchPath = `${baseSearchPath}&accreditation=${searchParams.office.accreditations.toString()}`;
                    baseSearchPath = `${baseSearchPath}&telco=${searchParams.office.telcoProviders.toString()}`;
                    baseSearchPath = `${baseSearchPath}&turnover=${searchParams.office.turnoverConditions.toString()}`;
                    break;
                  case 'Retail':
                    baseSearchPath = `${baseSearchPath}&development-type=${searchParams.retail.developmentType.toString()}`;
                    baseSearchPath = `${baseSearchPath}&turnover=${searchParams.retail.turnoverConditions.toString()}`;
                    baseSearchPath = `${baseSearchPath}&occupancy=${searchParams.retail.readyForOccupancy.toString()}`;
                    baseSearchPath = `${baseSearchPath}&parking-slots=${searchParams.retail.parkingSlots.toString()}`;
                    break;
                  case 'Industrial':
                    baseSearchPath = `${baseSearchPath}&electric-load=${searchParams.industrial.electricLoad.toString()}`;
                    baseSearchPath = `${baseSearchPath}&tenant-restrictions=${searchParams.industrial.tenantRestrictions.toString()}`;
                    break;
                  default:
                    baseSearchPath = `${baseSearchPath}`;
                    break;
                }
                getFiltersData(searchParams);
                navigate(baseSearchPath);
              }}
            >
              Search
            </Button>
          </div>
        </div>
      </div>
    </StyledDivWrapper>
  );
}

AdvancedFiltersComponent.propTypes = {
  propertyType: PropTypes.string,
  propertyLocation: PropTypes.string,
  sizeRange: PropTypes.array,
  priceRange: PropTypes.array,
  office: PropTypes.object,
  retail: PropTypes.object,
  industrial: PropTypes.object,
  getFiltersData: PropTypes.func,
};
export default AdvancedFiltersComponent;
