import React from 'react';
import PropTypes from 'prop-types';

import {
  Select,
  Checkbox,
  // Switch
} from 'antd';
const { Option } = Select;

function IndustrialAdditionalFiltersComponent({
  industrial,
  getElectricLoad,
  // getVehicleCapavity,
  // getLoadingDock,
  getTenantRestrictions,
}) {
  // const vehicleCapacities = [
  //   'All',
  //   'Closed Van',
  //   '10-Wheeler',
  //   '12-Wheeler',
  //   '14-Wheeler',
  //   '16-Wheeler',
  //   '18-Wheeler',
  // ];
  const tenantRestrictions = [
    'Storage',
    'Warehouse',
    'Manufacturing',
    'Logistic',
  ];
  const electricLoads = ['All', 'Single-Phase', 'Three-Phase'];

  return (
    <div>
      <div className="filter-field">
        <p>
          <b>Electric Load</b>
        </p>
        <div>
          <Select
            defaultValue={industrial.electricLoad}
            style={{ width: '100%' }}
            onChange={e => {
              getElectricLoad(e);
            }}
          >
            {electricLoads.map(electricLoad => (
              <Option key={electricLoad} value={electricLoad}>
                {electricLoad}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      {/* <div className="filter-field">
        <p>
          <b>Vehicle Capacities</b>
        </p>
        <div>
          <Select
            defaultValue={vehicleCapacities[0]}
            style={{ width: '100%' }}
            onChange={e => {
              getVehicleCapavity(e);
            }}
          >
            {vehicleCapacities.map(capacity => (
              <Option key={capacity} value={capacity}>
                {capacity}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      <div className="filter-field">
        <p>
          <b>
            Available loading dock?{' '}
            <Switch
              checkedChildren="YES"
              unCheckedChildren="NO"
              onChange={e => {
                getLoadingDock(e);
              }}
            />
          </b>
        </p>
      </div> */}

      <div className="filter-field">
        <p>
          <b>Tenant Restrictions</b>
        </p>
        <div>
          <Checkbox.Group
            defaultValue={industrial.tenantRestrictions}
            options={tenantRestrictions}
            onChange={e => {
              getTenantRestrictions(e);
            }}
          />
        </div>
      </div>
    </div>
  );
}

IndustrialAdditionalFiltersComponent.propTypes = {
  industrial: PropTypes.object,
  getElectricLoad: PropTypes.func,
  getTenantRestrictions: PropTypes.func,
  // getParkingSlots: PropTypes.func,
  // getTurnover: PropTypes.func,
};

export default IndustrialAdditionalFiltersComponent;
